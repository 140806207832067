// extracted by mini-css-extract-plugin
export var hero = "PostCard-module--hero--33X-l";
export var isActiveLink = "PostCard-module--is-active-link--2X1y6";
export var isCollapsed = "PostCard-module--is-collapsed--r-w2W";
export var isCollapsible = "PostCard-module--is-collapsible--yyTQL";
export var isPositionFixed = "PostCard-module--is-position-fixed--2bthW";
export var jsToc = "PostCard-module--js-toc--1uMp7";
export var post = "PostCard-module--post--1xedh";
export var postImage = "PostCard-module--postImage--tWO6w";
export var postSubtitle = "PostCard-module--postSubtitle--28of6";
export var postTag = "PostCard-module--postTag--2inEn";
export var postText = "PostCard-module--postText--1CznB";
export var postTitle = "PostCard-module--postTitle--dMVkN";
export var toc = "PostCard-module--toc--Y5Jvl";
export var tocLink = "PostCard-module--toc-link--8KZKJ";
export var tocList = "PostCard-module--toc-list---4JwR";