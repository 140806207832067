import React from "react"
import Row from "../../../components/Row"
import * as styles from "./Join.module.scss"
import Link from "gatsby-plugin-transition-link"
import getAppUrl from "@src/utils/getAppUrl"

const Join = () => {
  const appUrl = getAppUrl.hook()

  return (
    <Row className={styles.row}>
      <section className={styles.splash}>
        <div className={styles.splashText}>
          <h1 className={styles.splashTextTitle}>
            Enough said, let's start building
          </h1>
          <p className={styles.splashTextSubtitle}>
            Evaluate with a{" "}
            <span className={styles.splashTextSubtitleSpan}>
              30 Day Free Trial
            </span>{" "}
            and use canonic's fullstack solution to build internal tools
          </p>
          <div className={styles.ctas}>
            <a href={appUrl} target="_blank" rel="noopener noreferrer">
              <button borderless className={styles.ctasButton}>
                Try for free
              </button>
            </a>
            <Link
              className={styles.ctasLink}
              to={"/get-a-demo"}
              entry={{ length: 0.3 }}
              exit={{ length: 0.3 }}
            >
              Get a live demo
            </Link>
          </div>
        </div>
      </section>
    </Row>
  )
}

export default Join
