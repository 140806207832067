import React from "react"
import Link from "gatsby-plugin-transition-link"

import Layout from "@src/components/Layout"
import Splash from "../Features/components/Splash/Splash"
import ClientStrip from "../Home/sections/ClientStrip/ClientStrip"
import Posts from "./sections/Posts/Posts"
import Join from "./sections/Join/Join"
import CaseStudyIllustration from "@src/icons/v2/case-study-splash.png"
import * as styles from "./CaseStudies.module.scss"

const CaseStudies = () => {
  return (
    <Layout
      title="Case Studies"
      description="Our customers have built a variety of tools using Canonic. Read more about it below"
      url="https://canonic.dev/case-studies"
      //   ogImage={OG}
    >
      <Splash
        title="Customer Case Studies"
        subtitle="Our customers have built a variety of tools using Canonic. Read more about it below"
        ctas={
          <div className={styles.ctas}>
            <a href="/get-a-demo" rel="noopener noreferrer">
              <button borderless className={styles.ctasButton}>
                Get a Demo
              </button>
            </a>
            <Link
              className={styles.ctasLink}
              to={"/pricing"}
              entry={{ length: 0.3 }}
              exit={{ length: 0.3 }}
            >
              View Pricing Plans
            </Link>
          </div>
        }
        illustration={<img src={CaseStudyIllustration} />}
        reverseOnMobile
      />
      <ClientStrip />
      <Posts />
      <Join />
    </Layout>
  )
}

export default CaseStudies
