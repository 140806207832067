import React from "react"

import Row from "../../../components/Row/Row"
import PostCard from "../../components/PostCard/PostCard"
import tamarindChutney from "@src/icons/case-studies/TC.png"

import * as styles from "./Posts.module.scss"

const Posts = () => {
  const posts = [
    {
      feature_image: tamarindChutney,
      title:
        "Tamarind Chutney automates the return & exchange process for their Shopify Store.",
      custom_excerpt:
        "Tamarind Chutney automates the return & exchange process for their Shopify Store.",
      url: "/case-studies/tamarind-chutney",
      tags: [{ name: "Tooling" }],
    },
  ]
  return (
    <section>
      <Row className={styles.relatedPostsWrapper}>
        <ul className={styles.grid}>
          {posts?.map(post => (
            <PostCard post={post} />
          ))}
        </ul>
      </Row>
    </section>
  )
}

export default Posts
