import React from "react"
import { Link } from "gatsby"
import * as styles from "./PostCard.module.scss"

export default function PostCard({ post, isHero, isWide }) {
  const isHeroClass = isHero ? styles.hero : ""
  const isWideClass = isWide ? styles.wide : ""
  const modifierClasses = [isHeroClass, isWideClass].join(" ")
  return (
    <Link className={`${styles.post} ${modifierClasses}`} to={post.url}>
      <figure className={styles.postImage}>
        <img src={post.feature_image} />
      </figure>
      <div className={styles.postText}>
        <p className={styles.postTag}>{post.tags?.[0]?.name}</p>
        <h3 className={styles.postTitle}>{post.title}</h3>
      </div>
    </Link>
  )
}
